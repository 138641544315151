@import '~antd/dist/antd.css';

html {
  height: 100%;
  margin: 0 0 0 0;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.bumbleBeeTable tr:nth-child(2n) td {
  background-color: #f8f8f8 !important;
}

/* Antd mobile styling overrides */
@media (max-width: 986px) {
  .ant-modal-confirm .ant-modal-body {
    padding: 8px 8px 4px;
  }

  .ant-card-body {
    padding: 8px;
  }
  .ant-card-body.compact {
    padding: 0;
  }
}
