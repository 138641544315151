.headerIcon {
  display: inline-block;
  padding: 0 16px;
  font-size: 18px;
}

.appHeader {
  padding: 0;
}

@media (max-width: 991px) {
  .headerMenu {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    position: fixed;
    right: 0;
    top: 65px;
    z-index: 999;

    max-height: 0px;
    overflow-y: hidden;
    transition: max-height 0.3s;

    &.open {
      max-height: 64px;
    }
  }

  .desktopOnly {
    display: none;
  }
}

@media (min-width: 992px) {
  .mobileOnly {
    display: none;
  }

  .appHeader {
    padding: 0 50px;
  }
}
