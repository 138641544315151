.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.boldest {
  font-weight: 900;
}

.hoverBold {
  &:hover {
    font-weight: bold;
  }
}

.hoverBolder {
  &:hover {
    font-weight: bolder;
  }
}

.hoverBoldest {
  &:hover {
    font-weight: 900;
  }
}
