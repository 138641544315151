.container {
  float: right;
  position: relative;
  div {
    display: inline-block;
  }

  div:nth-child(n + 2) {
    display: block;
    position: absolute;
    top: 0;
    left: 6px;
  }
  div:nth-child(3) {
    left: 12px;
  }
}

.activeUserList {
  display: flex;
  cursor: default;
  > span {
    margin-left: 8px;
  }
}
