@import '../../../../../../theme/default/colors.scss';

.bell {
  font-size: 18px;
}

.unread {
  background-color: rgba(0, 0, 0, 0.02);
}

.notificationItem {
  padding: 12px;
}

.date {
  font-size: 12px;
  width: 100%;
  text-align: right;
  position: relative;
  display: inline-block;
}
