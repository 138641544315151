.app {
  &:hover {
    background-color: #5a1b18 !important;
  }
}

.loadingOverlay {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
  .product {
    width: 52px;
    height: 52px;
    background-color: #001529;
    font-size: 20px;
    color: white;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    border: 1px solid #00396e;
    justify-content: center;
    align-items: center;
    > span {
      font-size: 8px;
    }
    &:first-child {
    }
    &:last-child {
    }
    &:hover {
      background-color: #00396e;
      cursor: pointer;
    }
  }
  .invest {
  }
  .trading {
  }
  .marketing {
  }
}
