.fromLeft {
  transform-origin: left;
}

.fromRight {
  transform-origin: right;
}

.fromTop {
  transform-origin: top;
}

.fromBottom {
  transform-origin: bottom;
}

.fromCenter {
  transform-origin: center;
}

.VeryQuickLinear {
  transition: all 0.1s linear;
}

.VeryQuickOvershoot {
  transition: all 0.1s cubic-bezier(0.17, 0.67, 0.26, 1.58);
}

.VeryQuickAnticipate {
  transition: all 0.1s cubic-bezier(0.37, -0.74, 0.61, 1);
}

.VeryQuickAnticipateOvershoot {
  transition: all 0.1s cubic-bezier(0.51, -0.63, 0.48, 1.61);
}

.QuickLinear {
  transition: all 0.2s linear;
}

.QuickOvershoot {
  transition: all 0.2s cubic-bezier(0.17, 0.67, 0.26, 1.58);
}

.QuickAnticipate {
  transition: all 0.2s cubic-bezier(0.37, -0.74, 0.61, 1);
}

.QuickAnticipateOvershoot {
  transition: all 0.2s cubic-bezier(0.51, -0.63, 0.48, 1.61);
}

.Linear {
  transition: all 0.3s linear;
}

.Overshoot {
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.26, 1.58);
}

.Anticipate {
  transition: all 0.3s cubic-bezier(0.37, -0.74, 0.61, 1);
}

.AnticipateOvershoot {
  transition: all 0.3s cubic-bezier(0.51, -0.63, 0.48, 1.61);
}

.SlowLinear {
  transition: all 0.6s linear;
}

.SlowOvershoot {
  transition: all 0.6s cubic-bezier(0.17, 0.67, 0.26, 1.58);
}

.SlowAnticipate {
  transition: all 0.6s cubic-bezier(0.37, -0.74, 0.61, 1);
}

.SlowAnticipateOvershoot {
  transition: all 0.6s cubic-bezier(0.51, -0.63, 0.48, 1.61);
}

.VerySlowLinear {
  transition: all 1s linear;
}

.VerySlowOvershoot {
  transition: all 1s cubic-bezier(0.17, 0.67, 0.26, 1.58);
}

.VerySlowAnticipate {
  transition: all 1s cubic-bezier(0.37, -0.74, 0.61, 1);
}

.VerySlowAnticipateOvershoot {
  transition: all 1s cubic-bezier(0.51, -0.63, 0.48, 1.61);
}
