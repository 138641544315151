@import '../../../../theme/default/default.scss';

.linkText {
  color: #1890ff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  margin-left: 12px;
  padding: 0;
}

.WineMerchant {
  padding: 3px 6px 3px 6px;
  height: 24px;
  border-radius: 3px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  line-height: 18px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  float: left;
  margin-right: 12px;
}

.action {
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    color: $primary-color;
  }
}

.originalOfferText {
  font-size: 12px;
  font-style: italic;
}

.searchBar {
  margin-bottom: 12px;
}
.row {
}

.rowstock {
  background-color: rgb(225, 225, 255);
}

.rowbbx {
  background-color: rgb(248, 255, 225);
}

.row-still-red {
  //background-color: rgb(255, 250, 250);
}

.row-still-white {
  //background-color: rgb(255, 255, 250);
}

.row-sparkling-white {
  //background-color: rgb(255, 255, 250);
}

.row-still-rose {
  //background-color: rgb(255, 230, 240);
}

.row-sparkling-rose {
  //background-color: rgb(255, 230, 240);
}

.region-cell {
  //font-size: large;
  //text-align: right !important;
  //padding-right: 4px !important;
}

.wine-cell {
  //padding-left: 4px !important;
}
