.hidden {
  display: none;
}

.hoverOpacity0 {
  &:hover {
    opacity: 0;
  }
}

.hoverOpacity10 {
  &:hover {
    opacity: 0.1;
  }
}

.hoverOpacity20 {
  &:hover {
    opacity: 0.2;
  }
}

.hoverOpacity30 {
  &:hover {
    opacity: 0.3;
  }
}

.hoverOpacity40 {
  &:hover {
    opacity: 0.4;
  }
}

.hoverOpacity50 {
  &:hover {
    opacity: 0.5;
  }
}

.hoverOpacity60 {
  &:hover {
    opacity: 0.6;
  }
}

.hoverOpacity70 {
  &:hover {
    opacity: 0.7;
  }
}

.hoverOpacity80 {
  &:hover {
    opacity: 0.8;
  }
}

.hoverOpacity90 {
  &:hover {
    opacity: 0.9;
  }
}

.hoverOpacity100 {
  &:hover {
    opacity: 1;
  }
}

.opacity0 {
  opacity: 0;
}

.opacity10 {
  opacity: 0.1;
}

.opacity20 {
  opacity: 0.2;
}

.opacity30 {
  opacity: 0.3;
}

.opacity40 {
  opacity: 0.4;
}

.opacity50 {
  opacity: 0.5;
}

.opacity60 {
  opacity: 0.6;
}

.opacity70 {
  opacity: 0.7;
}

.opacity80 {
  opacity: 0.8;
}

.opacity90 {
  opacity: 0.9;
}

.opacity100 {
  opacity: 1;
}
