.background {
  background-color: white;
}

.fullScreen {
  padding: 0;
}

@media (min-width: 768px) {
  .background {
    padding: 32px;
  }
}
