@import '../../../../theme/default/default.scss';

.row {
  width: 100%;
  margin-bottom: 12px;
}

.rowHighlight {
  transition: all 0.9s;
}

.rowHighlight:hover {
  background-color: lightgrey;
}

.panelHeaderPending:first-child {
  outline: $warning-color solid 1px;
}

.imageThumbnailContainer {
  height: 150px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  margin: 6px;
  display: flex;
  flex-direction: row;
}

.image {
  width: auto;
  height: 100%;
}

.selected {
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.7);
  transform: translate(0px, -10px) scale(1.05);
  outline: white;
  outline-style: solid;
  outline-width: 1px;
}

.imageContainer {
  padding: 12px;
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  max-height: 100vh !important;
}

.search {
  margin-bottom: 24px;
}

.h1 {
  opacity: 0.5;
  margin-bottom: -12px;
}

.test {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  > img {
    max-width: 100%;
    height: 100%;
  }
}

.clickableTextInImage {
  background: transparent;
  text-align: center;
  width: 100%;
  text-shadow: black 0px 0px 8px, black 0px 0px 8px, black 0px 0px 8px, black 0px 0px 8px;

  &:hover {
    cursor: pointer;
  }
}

.notAllowed {
  &:hover {
    cursor: not-allowed;
  }
}
