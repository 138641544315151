.container {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  position: relative;
  flex-direction: column;
}

.fitToWidth {
  width: 98%;
}

.loginAlert {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.login {
  text-align: center;
  min-height: 250px;
  padding: 32px;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 6px;
  box-shadow: 2px 6px 6px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .withAlert {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  h1 {
    color: rgba(0, 0, 0, 0.1);
  }
  p {
    font-style: italic;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
  }
}

.affiliation {
  margin-top: 12px;
  h5 {
    color: rgba(255, 255, 255, 0.7);
    font-style: italic;
    font-size: 12px;
  }
}

.bg {
  z-index: 0;
  background: url('../../images/splash/crate_02.jpg') no-repeat;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
}

@media (min-width: 450px) {
  .fitToWidth {
    width: 400px;
  }
}
