.appContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0;
  border-bottom: 2px solid white;
  transition: all 0.25s;
  &.active {
    padding: 12px 0 12px 0;
  }
}

.appIcon {
  width: 52px;
  height: 52px;
  background-color: #001529;
  font-size: 20px;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  border: 1px solid #00396e;
  justify-content: center;
  align-items: center;
  > span {
    font-size: 8px;
  }
  &:first-child {
  }
  &:last-child {
  }
  &:hover {
    background-color: #00396e;
    cursor: pointer;
  }
}

.logoContainer {
  height: 48px;
  width: 48px;
  display: flex;
  flex-direction: row;
  border: 2px solid white;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  .logoText {
    color: white;
    font-size: 24px;
    margin-top: 10px;
    font-weight: bold;
    overflow: hidden;
  }
  .title {
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    margin-top: -16px;
    overflow: hidden;
  }
  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }

  img {
    padding: 12px;
    height: 64px;
    max-width: 64px;
    object-fit: container;
  }
}

.appTitle {
  width: 100%;
  height: 100%;
  padding-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: white;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    white-space: nowrap;
  }
}

.beta {
  color: rgb(197, 0, 0);
  position: absolute;
  top: 0;
  margin-left: -5px;
  margin-top: -5px;
  transform-origin: center;
  transform: rotate(37deg);
}

@media (max-width: 991px) {
  .appHostMenu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    &.collapsed {
      max-width: 0 !important;
      min-width: 0 !important;
      width: 0 !important;
      overflow: hidden;

      // Hide the expand/collapse button at the bottom of the sider
      > div:last-child {
        display: none;
      }
    }
  }
}
