$primary-color: #1890ff;
$link-color: #1890ff;
$success-color: #52c41a;
$warning-color: #faad14;
$error-color: #f5222d;
$font-size-base: 14px;
$heading-color: rgba(0, 0, 0, 0.85);
$text-color: rgba(0, 0, 0, 0.65);
$text-color-secondary: rgba(0, 0, 0, 0.45);
$disabled-color: rgba(0, 0, 0, 0.25);
$border-radius-base: 4px;
$border-color-base: #d9d9d9;
$box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);
$header-hover-bg: rgba(0, 0, 0, 0.025);

$invest-background: rgba(240, 238, 235, 1);
$invest-blue: rgba(34, 40, 81, 1);
$invest-lightblue: rgba(98, 109, 158, 1);
