// on mouse down
html .activeSizeSmallest {
  &:active {
    transform: scale(0.4);
  }
}

html .activeSizeSmaller {
  &:active {
    transform: scale(0.6);
  }
}

html .activeSizeSmall {
  &:active {
    transform: scale(0.8);
  }
}

html .activeSizeNormal {
  &:active {
    transform: scale(1);
  }
}

html .activeSizeBig {
  &:active {
    transform: scale(1.2);
  }
}

html .activeSizeBigger {
  &:active {
    transform: scale(1.4);
  }
}

html .activeSizeBiggest {
  &:active {
    transform: scale(1.6);
  }
}

// hover
.hoverSizeSmallest {
  &:hover {
    transform: scale(0.4);
  }
}

.hoverSizeSmaller {
  &:hover {
    transform: scale(0.6);
  }
}

.hoverSizeSmall {
  &:hover {
    transform: scale(0.8);
  }
}

.hoverSizeNormal {
  &:hover {
    transform: scale(1);
  }
}

.hoverSizeBig {
  &:hover {
    transform: scale(1.2);
  }
}

.hoverSizeBigger {
  &:hover {
    transform: scale(1.4);
  }
}

.hoverSizeBiggest {
  &:hover {
    transform: scale(1.6);
  }
}

// static
.sizeSmallest {
  transform: scale(0.4);
}

.sizeSmaller {
  transform: scale(0.6);
}

.sizeSmall {
  transform: scale(0.8);
}

.sizeNormal {
  transform: scale(1);
}

.sizeBig {
  transform: scale(1.2);
}

.sizeBigger {
  transform: scale(1.4);
}

.sizeBiggest {
  transform: scale(1.6);
}
