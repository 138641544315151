.image {
  margin: 6px;
  border-radius: 3px;
  &:hover {
    cursor: pointer;
  }
  img {
    border-radius: 3px;
  }
}

.active {
  background-color: rgba(0, 255, 0, 0.1);
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.2);
}

.icon {
  color: green;
  width: 64px;
  height: 64px;
  position: absolute;
  right: -8px;
  top: 8px;
  font-size: 24px;
}
