@import '../../theme/default/colors.scss';

.quickPrimary {
  animation: flashPrimary 1s;
}

.quickLink {
  animation: flashLink 1s;
}

.quickSuccess {
  animation: flashSuccess 1s;
}

.quickWarning {
  animation: flashWarning 1s;
}

.quickError {
  animation: flashError 1s;
}

.primary {
  animation: flashPrimary 2s;
}

.link {
  animation: flashLink 2s;
}

.success {
  animation: flashSuccess 2s;
}

.warning {
  animation: flashWarning 2s;
}

.error {
  animation: flashError 2s;
}

.slowPrimary {
  animation: flashPrimary 4s;
}

.slowLink {
  animation: flashLink 4s;
}

.slowSuccess {
  animation: flashSuccess 4s;
}

.slowWarning {
  animation: flashWarning 4s;
}

.slowError {
  animation: flashError 4s;
}

@keyframes flashPrimary {
  100% {
    background-color: transparent;
  }
  0% {
    background-color: rgba($primary-color, 0.2);
  }
}
@keyframes flashLink {
  100% {
    background-color: transparent;
  }
  0% {
    background-color: rgba($link-color, 0.2);
  }
}
@keyframes flashSuccess {
  100% {
    background-color: transparent;
  }
  0% {
    background-color: rgba($success-color, 0.2);
  }
}
@keyframes flashWarning {
  100% {
    background-color: transparent;
  }
  0% {
    background-color: rgba($warning-color, 0.2);
  }
}
@keyframes flashError {
  100% {
    background-color: transparent;
  }
  0% {
    background-color: rgba($error-color, 0.2);
  }
}
