.hoverPointer {
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
}

.hoverDefault {
  cursor: default;
  &:hover {
    cursor: default;
  }
}

.hoverDisabled {
  cursor: not-allowed;
  &:hover {
    cursor: not-allowed;
  }
}

.pointerEventsNone {
  pointer-events: none;
}
