@import '../../theme/default/colors.scss';

.focusColorPrimary {
  &:focus {
    color: $primary-color;
  }
}

.focusColorLink {
  &:focus {
    color: $link-color;
  }
}

.focusColorSuccess {
  &:focus {
    color: $success-color;
  }
}

.focusColorWarning {
  &:focus {
    color: $warning-color;
  }
}

.focusColorError {
  &:focus {
    color: $error-color;
  }
}

.focusColorWhite {
  &:focus {
    color: white;
  }
}

.activeColorPrimary {
  &:active {
    color: $primary-color;
  }
}

.activeColorLink {
  &:active {
    color: $link-color;
  }
}

.activeColorSuccess {
  &:active {
    color: $success-color;
  }
}

.activeColorWarning {
  &:active {
    color: $warning-color;
  }
}

.activeColorError {
  &:active {
    color: $error-color;
  }
}

.activeColorWhite {
  &:active {
    color: white;
  }
}

.hoverColorPrimary {
  &:hover {
    color: $primary-color;
  }
}

.hoverColorLink {
  &:hover {
    color: $link-color;
  }
}

.hoverColorSuccess {
  &:hover {
    color: $success-color;
  }
}

.hoverColorWarning {
  &:hover {
    color: $warning-color;
  }
}

.hoverColorError {
  &:hover {
    color: $error-color;
  }
}

.hoverColorWhite {
  &:hover {
    color: white;
  }
}

.colorPrimary {
  color: $primary-color;
}

.colorLink {
  color: $link-color;
}

.colorSuccess {
  color: $success-color;
}

.colorWarning {
  color: $warning-color;
}

.colorError {
  color: $error-color;
}

.colorWhite {
  color: white;
}
