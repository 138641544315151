.headerText {
  font-weight: bold;
  font-size: 40px;
  display: flex;
  justify-content: center;
}

.dayText {
  font-weight: bold;
  font-size: 30px;
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 15px;
}

@media (max-width: 1200px) {
  .headerText {
    font-size: 22px;
  }

  .dayText {
    font-size: 18px;
  }
}
