.refreshReminder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fffaf4;
  width: 100%;
}

.refreshButton {
  display: flex;
  align-self: center;
  color: #2567e5;
  background-color: #fffaf4;
  border-color: #2567e5;
}

.header {
  color: #a85d00;
  font-weight: bold;
  width: 100%;
}

.description {
  color: #a85d00;
  width: 100%;
}

.container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.exclamationCircle {
  font-size: 24px;
  color: #a85d00;
}
