@import '../../../../../../theme/default/colors.scss';

.action {
  display: inline-block;
  padding: 0 16px;
  cursor: pointer;
  transition: all 0.3s;
  > i {
    color: #1890ff;
    vertical-align: middle;
  }
  &:hover {
    background: $header-hover-bg;
  }
  &:global(.opened) {
    background-color: $header-hover-bg;
  }
}
.search {
  padding: 0 12px;
  &:hover {
    background: transparent;
  }
}
.account {
  .name {
    margin-left: 6px;
    vertical-align: middle;
  }
  .avatar {
    margin: 'calc((@{layout-header-height} - 24px) / 2)' 0;
    color: #1890ff;
    vertical-align: middle;
    background: rgba(255, 255, 255, 0.85);
  }
}

.mobileIcon {
  display: inline-block;
  padding: 0 16px;
  font-size: 18px;
}
